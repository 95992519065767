@import "bootstrap/dist/css/bootstrap.css";
.lightbox__title {
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #29303a;
}
.lightbox__description {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #29303a;
}
.lightbox__ingredients,
.lightbox__label {
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #97acb2;
  text-transform: uppercase;
}
.lightbox__label {
  color: #ed303b;
  margin-top: 20px;
}
.lightbox .ant-modal-header {
  border: none;
  padding-bottom: 0;
  padding-left: 40px;
  padding-right: 40px;
}
.lightbox .ant-modal-header .ant-modal-title {
  margin-top: 20px;
  height: 24px;
  font-size: 20px;
  font-weight: 500;
}
.lightbox .ant-modal-body {
  padding-top: 10px;
  padding-left: 40px;
  padding-right: 40px;
}
.lightbox .ant-modal-footer {
  display: none;
}
.lightbox__quantity {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ed303b;
  width: 56px;
  padding: 8px 15px;
  margin: 2px 0 0;
}
.lightbox__button {
  background-color: #eee;
  border: none;
}
.lightbox__add-button {
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  font-size: 16px;
  padding: 8px 15px;
  height: auto;
}
.lightbox__add-button:disabled {
  background-color: #d2ddd8;
  color: #fff;
  border: none;
  padding: 8px 15px;
}
.lightbox__add-button:disabled:hover {
  background-color: #d2ddd8;
  color: #fff;
  border: none;
}
.lightbox__account-settings .lightbox__footer {
  text-align: center;
  display: block;
}
.lightbox__account-settings .lightbox__footer .ant-btn.large-button {
  margin-top: 0;
  margin-bottom: 20px;
}
.ant-btn.large-button {
  padding: 22px 54px;
  border-radius: 3px;
  height: unset;
  margin-top: 30px;
}
.ant-btn.large-button span {
  font-size: 18px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}
.ant-btn.medium-button {
  padding: 14px 26px;
  border-radius: 3px;
  height: unset;
  margin-top: 30px;
  margin-bottom: 30px;
  line-height: normal;
}
.ant-btn.medium-button span {
  font-size: 18px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}
.ant-card {
  box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.1);
}
body {
  font-family: "Source Sans Pro", Calibri, Candara, Arial, sans-serif;
  font-size: 16px;
  line-height: 1.42857143;
  color: #222222;
  background-color: #fff;
  word-break: break-word;
}
.brand-color {
  color: #ed303b;
}
.ant-layout {
  background-color: #fff;
}
.main-content {
  max-width: 960px;
  margin: 0 auto;
  padding: 50px 0;
  width: 100%;
}
.hide {
  display: none !important;
}
div.ant-message {
  top: 90px;
  z-index: 1000;
}
hr {
  border-top: #eee;
}
.bold {
  font-weight: bold;
}
.ant-modal {
  top: 20px;
}
input.ant-input,
input {
  min-height: 44px;
}
input.ant-input:hover,
input:hover,
input.ant-input:focus,
input:focus {
  border-color: #ed303b;
  box-shadow: 0 0 0 2px rgba(237, 48, 59, 0.2);
}
p {
  font-size: 1.15em;
  margin: 0 0 10.5px;
  line-height: 1.5em;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Source Sans Pro", Calibri, Candara, Arial, sans-serif;
  font-weight: 300;
  line-height: 1.1;
  color: inherit;
}
h1 {
  font-weight: bold;
}
h3 {
  font-weight: bold;
}
ul li {
  font-size: 1.15em;
}
iframe,
picture,
figure {
  max-width: 100%;
}
.text-center {
  display: block;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 21px;
  font-size: 16px;
}
table th {
  vertical-align: bottom;
  border-bottom: 2px solid #dddddd;
  padding: 8px;
  line-height: 1.42857143;
  font-weight: 700;
}
table td {
  padding: 8px;
  line-height: 1.42857143;
  font-weight: normal;
  vertical-align: top;
  border-top: 1px solid #dddddd;
}
.app-order__location {
  margin: 16px 0 0;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #29303a;
}
.app-order__location a {
  text-decoration: underline;
  color: inherit;
}
.no-margin {
  margin: 0 !important;
}
.label-span {
  color: #000;
  font-weight: 900;
}
