.ant-layout-header {
  padding: 0;
  height: 84px;
  line-height: 84px;
}
.Navbar {
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
}
.Navbar .sbca-logo img {
  min-width: 115px;
  min-height: 30px;
  margin-right: 20px;
  margin-top: 0px;
}
@media (max-width: 480px) {
  .Navbar {
    justify-content: flex-start;
  }
}
.Navbar div {
  display: flex;
  align-items: center;
}
.Navbar .Logo {
  margin-right: 15px;
}
@media (max-width: 480px) {
  .Navbar .Logo {
    margin-left: 30px;
  }
}
.Navbar .nav-drawer__toggle {
  order: 1;
  cursor: pointer;
}
@media (max-width: 480px) {
  .Navbar .nav-drawer__toggle {
    order: 0;
    transform: rotate(180deg);
    margin-right: 30px;
  }
}
.Navbar .Navbar__nav-link {
  font-size: 18px;
  font-weight: normal;
  margin-right: 20px;
}
.Navbar .Navbar__nav-link svg {
  margin-left: 10px;
}
.Navbar .nav-link__active {
  color: #ed303b;
}
.Navbar__nav-wrapper {
  color: #ffffff;
  padding: 0 15px;
}
.Navbar__nav-link {
  font-family: "Source Sans Pro", Calibri, Candara, Arial, sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
}
.Navbar a {
  color: #ffffff;
}
