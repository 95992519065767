@font-face {
  font-family: "Kaleko105";
  src: url("../assets/fonts/@font-face-Kaleko105/Kaleko105-Bold.eot");
  src: url("../assets/fonts/@font-face-Kaleko105/Kaleko105-Bold.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/@font-face-Kaleko105/Kaleko105-Bold.woff") format("woff"), url("../assets/fonts/@font-face-Kaleko105/Kaleko105-Bold.ttf") format("truetype"), url("../assets/fonts/@font-face-Kaleko105/Kaleko105-Bold.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* The prefix to use on all css classes from ant.*/
/* An override for the html selector for theme prefixes*/
/* -------- Colors -----------*/
/* SBCA Brand */
/* Base Scaffolding Variables*/
/* ---*/
/* Background color for `<body>`*/
/* Base background color for most components*/
/* Popover background color*/
/* https://github.com/ant-design/ant-design/issues/20210*/
/* vertical paddings*/
/* containers*/
/* small containers and buttons*/
/* Form controls and items*/
/* small items*/
/* more small*/
/* vertical padding for all form controls*/
/* vertical margins*/
/* containers*/
/* small containers and buttons*/
/* Form controls and items*/
/* small items*/
/* more small*/
/* height rules*/
/* The background colors for active and hover states for things like*/
/* list items or table cells.*/
/* ICONFONT*/
/* LINK*/
/* Animation*/
/* Border color*/
/* base border outline a component*/
/* split border inside a component*/
/* width of the border for a component*/
/* style of a components border*/
/* Outline*/
/* background of header and selected item*/
/* Default grey background color*/
/* Disabled states*/
/* Shadow*/
/* Buttons*/
/* Checkbox*/
/* Descriptions*/
/* Divider*/
/* Dropdown*/
/* Empty*/
/* Radio*/
/* Radio buttons*/
/* Media queries breakpoints*/
/* Extra small screen / phone*/
/* Small screen / tablet*/
/* Medium screen / desktop*/
/* Large screen / wide desktop*/
/* Extra large screen / full hd*/
/* Extra extra large screen / large desktop*/
/* provide a maximum*/
/* Grid system*/
/* Layout*/
/* Layout light theme*/
/* z-index list, order by `z-index`*/
/* Animation*/
/* Modal*/
/* Tooltip*/
/*CollapsePanel*/
/*Dropdown*/
/* Form*/
/* ---*/
/* Input*/
/* ---*/
/* Mentions*/
/* ---*/
/* Select*/
/* ---*/
/* Normal 24px*/
/* Cascader*/
/* ---*/
/* Cascader*/
/* ----*/
/* Anchor*/
/* ---*/
/* Tooltip*/
/* ---*/
/* Tooltip max width*/
/* Tooltip text color*/
/* Tooltip background color*/
/* Tooltip arrow width*/
/* Tooltip distance with trigger*/
/* Tooltip arrow color*/
/* Popover*/
/* ---*/
/* Popover body background color*/
/* Popover text color*/
/* Popover maximum width*/
/* Popover arrow width*/
/* Popover arrow color*/
/* Popover outer arrow width*/
/* Popover outer arrow color*/
/* Popover distance with trigger*/
/* Modal*/
/* --*/
/* Progress*/
/* --*/
/* Menu*/
/* ---*/
/* dark theme*/
/* Spin*/
/* ---*/
/* Table*/
/* --*/
/* Sorter*/
/* Legacy: `table-header-sort-active-bg` is used for hover not real active*/
/* Filter*/
/* Tag*/
/* --*/
/* TimePicker*/
/* ---*/
/* Calendar*/
/* ---*/
/* Carousel*/
/* ---*/
/* Badge*/
/* ---*/
/* Rate*/
/* ---*/
/* Card*/
/* ---*/
/* Comment*/
/* ---*/
/* Tabs*/
/* ---*/
/* BackTop*/
/* ---*/
/* Avatar*/
/* ---*/
/* Switch*/
/* ---*/
/* Pagination*/
/* ---*/
/* PageHeader*/
/* ---*/
/* Breadcrumb*/
/* ---*/
/* Slider*/
/* ---*/
/* Tree*/
/* ---*/
/* Collapse*/
/* ---*/
/* Skeleton*/
/* ---*/
/* Transfer*/
/* ---*/
/* Message*/
/* ---*/
/* Motion*/
/* ---*/
/* Alert*/
/* ---*/
/* List*/
/* ---*/
/* Statistic*/
/* ---*/
/* Drawer*/
/* ---*/
/* Timeline*/
/* ---*/
/* Typography*/
/* ---*/
/* Upload*/
/* ---*/
/* Steps*/
/* ---*/
/* Notification*/
/* ---*/
/*  Result*/
/* ---*/
