.background {
  background-color: #122034;
  height: 100%;
}
.SignIn {
  color: #fff;
}
.SignIn h1 {
  color: #fff;
  padding-top: 20px;
  font-size: 1.5em;
}
.SignIn a {
  color: #fff;
}
.SignIn a:hover {
  text-decoration: underline;
}
.SignIn__column-left {
  background-color: #122034;
  padding: 10px 30px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  text-align: center;
}
.order__button {
  display: flex;
  height: auto;
  align-items: center;
  margin: 10px 0 10px;
  font-size: 16px;
  padding: 8px 15px;
  text-align: center;
  width: 100%;
}
.order__button span {
  text-align: center;
  width: 100%;
}
