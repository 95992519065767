.sbca-logo img {
  max-width: 120px;
}
.Navbar__nav-link {
  display: none;
}
@media (min-width: 768px) {
  .sbca-logo img {
    max-width: 220px;
  }
  .Navbar__nav-link {
    display: block;
  }
}
